<template>
  <div id="user">
    <div class="mine-title">{{ $t('route.user') }}</div>
    <div class="content">
      <div class="cell">
        <span>{{ $t('mine.user_1') }}{{ maskedEmail || maskedPhone}}</span>
      </div>
      <div class="cell">
        <span>{{ $t('mine.user_2') }}</span>
        <a-button type="primary" class="cell-btn" ghost @click="handleFormVisible('resetPwd')">
          {{ hasPwd?$t('mine.user_8'):$t('mine.user_3') }}
        </a-button>
      </div>
      <div class="cell">
        <span>{{ $t('mine.user_5') }}：{{ info.email?$t('mine.user_6'):$t('mine.user_7')}}</span>
        <a-button type="primary" class="cell-btn" ghost @click="handleFormVisible('bindEmail')">
          {{ info.email?$t('mine.user_8'):$t('mine.user_9') }}
        </a-button>
      </div>
      <div class="cell">
        <span>{{ $t('mine.user_10') }}：{{ info.mobile?$t('mine.user_6'):$t('mine.user_7')}}</span>
        <a-button type="primary" class="cell-btn" ghost @click="handleFormVisible('bindMobile')">
          {{ info.mobile?$t('mine.user_8'):$t('mine.user_9') }}
        </a-button>
      </div>
      <div class="cell" v-if="info.share_link&&isSharer">
        <span>{{ $t('mine.user_15') }}：<span class="share-url">{{ info.share_link }}</span></span>
        <a-button type="primary" class="cell-btn copy-btn" ghost @click="handleCopy">
          {{ $t('mine.user_16') }}
        </a-button>
      </div>
      <div class="cell" v-if="info.share_link&&isSharer">
        <span>{{ $t('mine.user_18') }}：</span>
        <vue-qr 
          :text="info.share_link" 
          logoSrc="https://img.triproaming.cn/triproaming/common/travelesim-logo.png" 
          :size="160" 
          :margin="0" 
          :correctLevel="2"
          :logoScale="0.25" 
          >
        </vue-qr>
      </div>
    </div>
    <reset-info-modal :visible="visible" :formType="formType" @close="visible = false"/>
  </div>
</template>
<script>
import VueQr from 'vue-qr'
import Clipboard from 'clipboard';
import ResetInfoModal from '../components/ResetInfoModal.vue';
import { message } from 'ant-design-vue'
import { mapState,mapGetters } from 'vuex';
export default {
  components: {
    VueQr,
    ResetInfoModal,    
  },
  data() {
    return {
      visible:false,
      formType:'',
    }
  },
  computed: {
    ...mapState('user',['info']),
    ...mapGetters('user', ['hasPwd','isSharer']),
    maskedEmail() {
      // 分隔邮箱地址中的用户名和域名
      const { email } = this.info
      if(!email) return false
      const [username, domain] = email.split('@');
      // 将用户名部分的前三个字符保留，其余替换为星号
      const maskedUsername = username.slice(0, 3) + '*'.repeat(username.length - 3);
      // 返回处理后的邮箱地址
      return `${maskedUsername}@${domain}`;
    },
    maskedPhone() {
      // 隐藏手机号码中的部分数字
      const { mobile } = this.info
      if(!mobile) return false
      const visibleDigits = 4;
      const maskedPart = '*'.repeat(mobile.length - 5); // 中间部分用星号代替
      const visiblePart = mobile.substring(mobile.length - visibleDigits);
      return mobile.substring(0, 3) + maskedPart + visiblePart;
    }
  },
  methods: {
    handleFormVisible(type){
      this.formType = type
      this.visible = true
    },
    handleCopy() {
      let clipboard = new Clipboard('.copy-btn', {
        text: () => {
          return this.info.share_link;
        }
      })
      clipboard.on('success', e => {
        message.success(this.$t('mine.flow_3'))
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        message.warning(this.$t('mine.flow_4'))
        clipboard.destroy()
      })
    },
  },
}
</script>
<style lang="scss" scoped>
#user {
  .content {
    margin-top: 20px;
    border-top: 1px solid #EBEBEB;
    .cell {
      color: #666666;
      padding: 20px 0;
      border-bottom: 1px solid #EBEBEB;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:last-child {
        border: none;
      }
      .share-url {
        color: $color;
        user-select: text;
        word-break: break-all;
      }
      .cell-btn{
        min-width: 100px;
        height: 40px;
        color: $color;
        border-radius: 0;
        border-color: $color;
      }
      .copy-btn{
        margin-left: 10px;
      }
    }
  }
  @include respond-to('phone'){
    .content {
      .cell {
        font-size: 14px;
        padding: 12px 0;
        .cell-btn {
          min-width: 80px;
          height: 36px;
        }
      }
    }
  }
}
</style>