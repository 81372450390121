<template>
   <a-modal
      :visible="visible"
      :footer="null"
      :width="400"
      :title="formTitle"
      :destroyOnClose="true"
      @cancel="$emit('close')"
      wrapClassName="reset-info-modal"
    >
    <a-spin :spinning="isLoading" size="large">
      <!-- 修改密码 -->
      <a-form-model v-show="formType === 'resetPwd'" :model="resetForm" :rules="resetRules" ref="ResetForm" @submit="handleReset" @submit.native.prevent>
        <a-form-model-item prop="old_pwd" v-if="hasPwd">
          <a-input-password v-model="resetForm.old_pwd" allow-clear type="password" :placeholder="$t('mine.user_11')">
            <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item prop="new_pwd">
          <a-input-password v-model="resetForm.new_pwd" allow-clear type="password" :placeholder="$t('mine.user_12')">
            <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item prop="repassword">
          <a-input-password v-model="resetForm.repassword" allow-clear type="password" :placeholder="$t('login.tips_2')">
            <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item style="margin-bottom: 0;">
          <a-button type="primary" block html-type="submit">
            {{ $t('common.ok') }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <!-- 绑定手机 -->
      <a-form-model v-show="formType === 'bindMobile'" :model="bindMobileForm" :rules="bindMobileRules" ref="BindMobileForm" @submit="handleBindMobile" @submit.native.prevent>
        <a-form-model-item prop="area" >
          <a-select
            show-search
            v-model="bindMobileForm.area"
            :placeholder="$t('login.tips_14')"
            :filter-option="filterOption"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          >
            <a-select-option v-for="item in areaCode" :key="item[`label_${$i18n.locale}`]">
              {{ item[`label_${$i18n.locale}`] }}
            </a-select-option>
            <template #notFoundContent>
              <div class="select-empty">
                <a-empty :image="require('@/assets/search_none.png')">
                  <span slot="description" class="empty-text"> {{$t('common.search_3')}} </span>
                </a-empty>
              </div>
            </template>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="mobile">
          <a-input v-model="bindMobileForm.mobile" type="number" allow-clear :placeholder="$t('login.tips_1')">
            <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="code">
          <a-input class="input-code" v-model="bindMobileForm.code" allow-clear :placeholder="$t('login.tips_4')">
            <a-icon slot="prefix" type="message" style="color:rgba(0,0,0,.25)" />
            <a-button slot="addonAfter" type="link" class="btn-link" :loading="countdownLoading" @click="handleSendCode">
              {{ countingDown ? `${countdown} ${$t('login.tips_12')}` : $t('login.tips_5') }}
            </a-button>
          </a-input>
        </a-form-model-item>
        <a-form-model-item style="margin-bottom: 0;">
          <a-button type="primary" block html-type="submit">
            {{ $t('common.ok') }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <!-- 绑定邮箱 -->
      <a-form-model v-show="formType === 'bindEmail'" :model="bindEmailForm" :rules="bindEmailRules" ref="BindEmailForm" @submit="handleBindEmail" @submit.native.prevent>
        <a-form-model-item prop="email">
            <a-auto-complete v-model="bindEmailForm.email" allow-clear :placeholder="$t('login.tips_0')" @search="handleSearch">
              <a-input>
                <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
              </a-input>
              <template slot="dataSource">
                <a-select-option v-for="email in result" :key="email">
                  {{ email }}
                </a-select-option>
              </template>
            </a-auto-complete>
          </a-form-model-item>
        <a-form-model-item prop="code">
          <a-input class="input-code" v-model="bindEmailForm.code" allow-clear :placeholder="$t('login.tips_4')">
            <a-icon slot="prefix" type="message" style="color:rgba(0,0,0,.25)" />
            <a-button slot="addonAfter" type="link" class="btn-link" :loading="countdownLoading" @click="handleSendCode">
              {{ countingDown ? `${countdown} ${$t('login.tips_12')}` : $t('login.tips_5') }}
            </a-button>
          </a-input>
        </a-form-model-item>
        <a-form-model-item style="margin-bottom: 0;">
          <a-button type="primary" block html-type="submit">
            {{ $t('common.ok') }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </a-modal>
</template>
<script>
import { mapActions,mapGetters } from 'vuex';
import { AutoComplete,message } from 'ant-design-vue'
import { SendCodeAPI,UserChangePwdAPI,UserBindMobileAPI,UserBindEmailAPI,UserSetPwdAPI } from '../api/api'
import { validatorEmail,validatorMobile,validatorPassword } from '../utils/regex'
import areaCode from '../common/areacode'
import MD5 from "md5";

export default {
  name:'ResetInfoModal',
  components: {
    [AutoComplete.name]:AutoComplete,
  },
  props:{
    visible:{
      type:Boolean,
      default:false
    },
    formType:{
      type:String, //bindEmail bindMobile resetPwd
    }
  },
  data() {
    return {
      areaCode:Object.freeze(areaCode),
      isLoading:false,
      timer:null,
      result: [],
      countdown: 60,
      countdownLoading:false,
      countingDown: false,
      resetForm:{
        old_pwd: '',
        new_pwd: '',
        repassword: ''
      },
      bindMobileForm:{
        area: undefined,
        mobile: '',
        code: ''
      },
      bindEmailForm:{
        email: '',
        code: ''
      }
    }
  },
  computed:{
    ...mapGetters('user', ['hasPwd']),
    formTitle(){
      const titles = {
        'bindEmail': 'user_5',
        'bindMobile': 'user_10',
        'resetPwd': 'user_4',
      };
      const titleKey = titles[this.formType]
      if(!this.hasPwd&&this.formType==='resetPwd') return this.$t('mine.user_17') 
      return this.$t(`mine.${titleKey}`) 
    },
    resetRules() {
      return {
        old_pwd: [
          { required: this.hasPwd, message: this.$t('mine.user_11'), trigger: 'blur' },
        ],
        new_pwd: [
          { required: true, message: this.$t('mine.user_12'), trigger: 'blur' },
        ],
        repassword: [
          { required: true, message: this.$t('login.tips_2'), trigger: 'blur' },
        ],
      }
    },
    bindMobileRules(){
      return {
        area: [
          { required: true, message: this.$t('login.tips_14'), trigger: 'blur' },
        ],
        mobile: [
          { required: true, message: this.$t('login.tips_1'), trigger: 'blur' },
        ],
        code: [
          { required: true, message: this.$t('login.tips_4'), trigger: 'blur' },
        ],
      }
    },
    bindEmailRules(){
      return {
        email: [
          { required: true, message: this.$t('login.tips_0'), trigger: 'blur' },
        ],
        code: [
          { required: true, message: this.$t('login.tips_4'), trigger: 'blur' },
        ],
      }
    }
  },
  methods: {
    ...mapActions('user', ['GetInfo']),
    async handleSendCode() {
      if (!this.countingDown) {
        try {
          let params = {}
          if(this.formType === 'bindMobile'){
            const { area,mobile } = this.bindMobileForm
            if(!area){
              message.warning(this.$t('login.tips_14'))
              return
            }
            if(!validatorMobile(mobile)){
              message.warning(this.$t('login.tips_16'))
              return
            }
            params = {type:'bind',area:area.match(/\d+/)[0],text:mobile}
          }else{
            const { email } = this.bindEmailForm
            if(!validatorEmail(email)){
              message.warning(this.$t('login.tips_15'))
              return
            }
            params = {type:'bind',text:email}
          }
          this.countdownLoading = true;
          await SendCodeAPI(params)
          this.countdownLoading = false;
          this.countingDown = true;
          this.countdown = 60;
        } catch (error) {
          this.countdownLoading = false;
        }
        this.timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(this.timer);
            this.countingDown = false;
          }
        }, 1000);
      }
    },
    async handleReset(){
      await this.$refs.ResetForm.validate()
      const { old_pwd, new_pwd, repassword } = this.resetForm
      if(new_pwd !== repassword) {
        message.warning(this.$t('login.reset'))
        return 
      }
      if(!validatorPassword(new_pwd)){
        message.warning(this.$t('login.tips_17'))
        return 
      }
      const apiMethod = this.hasPwd ? UserChangePwdAPI : UserSetPwdAPI;
      try {
        const params = {
          ...(this.hasPwd&&{old_pwd: MD5(old_pwd)}),
          new_pwd: MD5(new_pwd),
        }
        this.isLoading = true;
        const { msg } = await apiMethod(params)
        this.isLoading = false;
        message.success(msg)
        this.$refs.ResetForm.resetFields();
        this.handleClose()
      } catch (error) {
        this.isLoading = false;
      }
    },
    async handleBindMobile(){
      await this.$refs.BindMobileForm.validate()
      try {
        const { area,mobile,code } = this.bindMobileForm
        const params = {
          area:area.match(/\d+/)[0],
          mobile,
          code
        }
        this.isLoading = true;
        const { msg } = await UserBindMobileAPI(params)
        this.isLoading = false;
        message.success(msg)
        this.$refs.BindMobileForm.resetFields();
        this.handleClose()
      } catch (error) {
        this.isLoading = false;
      }
    },
    async handleBindEmail(){
      await this.$refs.BindEmailForm.validate()
      try {
        const { email, code } = this.bindEmailForm
        this.isLoading = true;
        const { msg } = await UserBindEmailAPI({ email, code })
        this.isLoading = false;
        message.success(msg)
        this.$refs.BindEmailForm.resetFields();
        this.handleClose()
      } catch (error) {
        this.isLoading = false;
      }
    },
    async handleClose(){
      try {
        this.isLoading = true;
        await this.GetInfo()
        this.isLoading = false;
        this.$emit('close')
      } catch (error) {
        this.isLoading = false;
        this.$emit('close')
      }
    },
    handleSearch(value) {
      let result;
      if (!value || value.indexOf('@') >= 0) {
        result = [];
      } else {
        result = ['gmail.com','yahoo.com','icloud.com','hotmail.com','outlook.com'].map(domain => `${value}@${domain}`);
      }
      this.result = result;
    },
    filterOption(input, option){
      return  option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
  },
}
</script>
<style lang="scss" scoped>
.reset-info-modal{
  .ant-form {
    .ant-form-item{
      margin-bottom: 20px;
      /deep/ .ant-input{
        height: 40px;
        border:none;
        border-radius: 3px;
        background-color: #EEEEEE;
        &:focus{
          box-shadow:0 0 0 2px rgba(100, 46, 199, 0.5);
        }
      }
      /deep/ .ant-select{
        .ant-select-selection{
          height: 40px;
          border:none;
          border-radius: 3px;
          background-color: #EEEEEE;
          &:focus{
            box-shadow:0 0 0 2px rgba(100, 46, 199, 0.5);
          }
          .ant-select-selection__rendered{
            line-height: 40px;
          }
        }
      }
      /deep/ .ant-select-open .ant-select-selection{
          box-shadow:0 0 0 2px rgba(100, 46, 199, 0.5);
        }
      .input-code{
        /deep/ .ant-input{
          border-radius:3px 0 0 3px;
          &:focus{
            border-radius: 3px;
          }
        }
      }
      /deep/ .ant-input-group-addon{
        border: none;
        background-color: #EEEEEE;
        .ant-btn-loading{
          &::before{
            background-color: transparent;
          }
          .anticon{
            vertical-align:0;
          }
        }
      }
      /deep/ .ant-btn-block{
        height: 42px;
        border-color:$color;
        background-color:$color;
      }
      
    }
  }
  .btn-link{
    height: unset;
    padding: 0;
    color:$color;
  }
}
</style>